exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-cordwells-resources-js": () => import("./../../../src/pages/About/CordwellsResources.js" /* webpackChunkName: "component---src-pages-about-cordwells-resources-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/About/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-locations-js": () => import("./../../../src/pages/About/Locations.js" /* webpackChunkName: "component---src-pages-about-locations-js" */),
  "component---src-pages-additional-fees-jsx": () => import("./../../../src/pages/additionalFees.jsx" /* webpackChunkName: "component---src-pages-additional-fees-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policies-js": () => import("./../../../src/pages/policies.js" /* webpackChunkName: "component---src-pages-policies-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/PrivacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-safe-delivery-guidelines-js": () => import("./../../../src/pages/SafeDeliveryGuidelines.js" /* webpackChunkName: "component---src-pages-safe-delivery-guidelines-js" */),
  "component---src-pages-services-concrete-range-js": () => import("./../../../src/pages/Services/ConcreteRange.js" /* webpackChunkName: "component---src-pages-services-concrete-range-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/Services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-landscape-supplies-js": () => import("./../../../src/pages/Services/LandscapeSupplies.js" /* webpackChunkName: "component---src-pages-services-landscape-supplies-js" */),
  "component---src-pages-terms-of-trade-js": () => import("./../../../src/pages/TermsOfTrade.js" /* webpackChunkName: "component---src-pages-terms-of-trade-js" */)
}

